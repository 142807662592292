
type AccountContextProvider = Pick<AccountContext, 'setAccountId'>;
type AccountContextClient = Pick<AccountContext, 'getAccountId'> & {
    subscribe: (accountSessionChangedListener: AccountContextChangedListener) => void
    unsubscribe: (accountSessionChangedListener: AccountContextChangedListener) => void
}

/**
 * Can be subscribed with the Account Context and will be notified whenever the account id changes.
 */
type AccountContextChangedListener = (accountId: string) => void

/**
 * The AccountContext stores the account id. Applications can access it to request and display the
 * account information.
 */
export class AccountContext {
    private accountId: string;
    private listener: AccountContextChangedListener[] = [];

    constructor(accountId: string) {
        this.accountId = accountId;
    }

    public setAccountId (accountId: string) {
        this.accountId = accountId;
        this.listener.forEach(listener => listener(accountId))
    }

    public getAccountId (): string {
        return this.accountId;
    }

    public subscribe(listener: AccountContextChangedListener): void {
        console.warn('accountContext is deprecated. Please use: sharedParams.subscribe(\'root.account.id\', ...)');
        if (this.listener.indexOf(listener) < 0) {
            if (this.accountId) {
                listener(this.accountId);
            }
            this.listener.push(listener);
        }
    }

    public unsubscribe(listener: AccountContextChangedListener): void {
        const index = this.listener.indexOf(listener);
        if (index >= 0) {
            this.listener.splice(index, 1);
        }
    }

    public forProvider(): AccountContextProvider {
        return { setAccountId: this.setAccountId.bind(this) }
    }
    
    public forClient(): AccountContextClient {
        return { 
            getAccountId: this.getAccountId.bind(this),
            subscribe: this.subscribe.bind(this),
            unsubscribe: this.unsubscribe.bind(this)
        }
    }
}