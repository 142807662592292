type Callback<T = unknown> = (value?: T) => void;

export class SharedParams {

    private params = new Map<string, unknown>();
    private subscriptions = new Map<string, Callback[]>();

    public subscribe<T = unknown>(key: string, callback: Callback<T>): void {
        if (this.subscriptions.has(key)) {
            this.subscriptions.get(key).push(callback);
        } else {
            this.subscriptions.set(key, [callback]);
        }
        if (this.params.has(key)) {
            callback(this.params.get(key) as T);
        }
    }

    public unsubscribe<T = unknown>(key: string, callback: Callback<T>): void {
        const subscribers = this.subscriptions.get(key);

        const index = subscribers.indexOf(callback);
        if (index >= 0) {
            subscribers.splice(index, 1);
        }
    }

    public set<T = unknown>(key: string, value: T): void {
        this.params.set(key, value);
        if (this.subscriptions.has(key)) {
            this.subscriptions.get(key).forEach(callback => callback(value));
        }
    }

    public get<T = unknown>(key: string): T {
        return this.params.get(key) as T;
    }

    public has(key: string): boolean {
        return this.params.get(key) !== null && this.params.get(key) !== undefined;
    }

    public delete(key: string): void {
        this.params.delete(key);
    }
}

export const sharedParams = new SharedParams();