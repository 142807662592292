import { AccountUser, User } from "@iplabs/user-management-sdk";

type AuthProvider = Pick<AuthStore, 'setToken'>;
type AuthClient = Pick<AuthStore, 'getToken'> & {
    subscribe: (tokenChangedListener: TokenChangedListener) => void
    unsubscribe: (tokenChangedListener: TokenChangedListener) => void
}

/**
 * Can be subscribed with an AuthStore and will be notified whenever the token changes.
 */
type TokenChangedListener = (token: string) => void

/**
 * The AuthStore stores the authentication token for all myportfolio backend communication and
 * is mainly used as a wrapper to allow single-spa applications to gain access to this token.
 */
export class AuthStore {
    private token: string = '';
    private listeners: TokenChangedListener[] = [];
    private self: User | null = null;
    private accountUsers: Record<string, AccountUser> = {};

    public setToken (token: string) {
        this.token = token;
        this.listeners.forEach(listener => listener(token))
    }

    public getToken (): string {
        return this.token;
    }

    public subscribe(tokenChangedListener: TokenChangedListener): void {
        console.warn('authClient is deprecated. Please use: sharedParams.subscribe(\'root.auth.accessToken\', ...)');
        if (this.listeners.indexOf(tokenChangedListener) < 0) {
            tokenChangedListener(this.token);
            this.listeners.push(tokenChangedListener);
        }
    }

    public unsubscribe(tokenChangedListener: TokenChangedListener): void {
        const index = this.listeners.indexOf(tokenChangedListener);
        if (index >= 0) {
            this.listeners.splice(index, 1);
        }
    }

    public forAuthProvider(): AuthProvider {
        return { setToken: this.setToken.bind(this) }
    }
    
    public forAuthClient(): AuthClient {
        return { 
            getToken: this.getToken.bind(this),
            subscribe: this.subscribe.bind(this),
            unsubscribe: this.unsubscribe.bind(this)
        }
    }
}
