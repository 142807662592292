import { registerApplication, start } from "single-spa";
import { AccountContext } from "./AccountContext";
import { initialRedirect } from "./redirects";
import { sharedParams } from "./SharedParams";

import { authStore } from './auth';

function myportfolioActiveWhen (location: Location): boolean {
  const path = location.pathname;
  return path.includes('myproducts') || path.includes('myusermanagement');
}

function navigationActiveWhen (location: Location): boolean {
  const path = location.pathname;
  return !path.startsWith('/login');
}

/**
 * @returns the account id that is part of the path 
 */
function accountIdFromLocation (location: Location): string {
  const segment = location.pathname.split('/')[1];
  if (['admin', 'login'].includes(segment)) {
    return '';
  } else {
    return segment;
  }
}

function customProps(_appName: string, _location: Location) {
  const accountId = sharedParams.get<string>('root.account.id');
  return {
    accountContext: new AccountContext(accountId).forClient(),
    authClient: authStore.forAuthClient(),
    sharedParams,
  };
}

window.addEventListener('single-spa:before-mount-routing-event', () => {
  const accountId = accountIdFromLocation(location);
  sharedParams.set('root.account.id', accountId);
});

registerApplication({
  name: "myportfolio",
  app: (_props) => System.import("myportfolio"),
  activeWhen: [myportfolioActiveWhen],
  customProps
});

registerApplication({
  name: "@iplabs/myportfolio-navigation",
  app: (_props) => System.import("@iplabs/myportfolio-navigation"),
  activeWhen: [navigationActiveWhen],
  customProps
});


registerApplication({
  name: "@iplabs/myshops",
  app: (_props) => System.import("@iplabs/myshops"),
  activeWhen: ["/:accountId/myshops"],
  customProps
});

registerApplication({
  name: "@iplabs/feature-toggles",
  app: (_props) => System.import("@iplabs/feature-toggles"),
  activeWhen: ["/:accountId/myshops/features"],
  customProps
});

registerApplication({
  name: "@iplabs/account-management",
  app: (_props) => System.import("@iplabs/account-management"),
  activeWhen: ["/:accountId/account-management", "/admin"],
  customProps
});

registerApplication({
  name: "cfs-self-service",
  app: (_props) => System.import("cfs-self-service"),
  activeWhen: ["/:accountId/storage"],
  customProps
});

registerApplication({
  name: "@iplabs/mycontent",
  app: (_props) => System.import("@iplabs/mycontent"),
  activeWhen: ["/:accountId/mycontent"],
  customProps
});

registerApplication({
  name: "@iplabs/layout-editor",
  app: (_props) => System.import("@iplabs/layout-editor"),
  activeWhen: ["/:accountId/mycontent/layoutEditor"],
  customProps
});

registerApplication({
  name: "@iplabs/analytics-platform",
  app: (_props) => System.import('@iplabs/analytics-platform'),
  activeWhen: ["/:accountId/analytics"],
  customProps,
});

/* if user tries to access the layout-editor directly, we redirect them to the layouts manager */
initialRedirect((path) => {
  if (path.endsWith('layoutEditor')) return path.slice(0, path.lastIndexOf('/')) + '/layouts';
});

start({
  urlRerouteOnly: true,
});
