/**
 * Use this function to force redirects on entry points.
 * E.g. if the user tries to access `mycontent/layoutEditor` by using a deep-link
 * then they should be redirected to `mycontent/layouts`.
 * This redirect only applies to the first accessed url.
 */
export function initialRedirect(getRedirect: (path) => string | undefined): void {
    const redirectTo = getRedirect(window.location.pathname);
    if (redirectTo) {
        window.location.href = redirectTo;
    }
}

/**
 * Lookup the initially requested url and return it.
 * The url is stored in the session storage.
 */
export function readAndClearRedirectUri(): string | null {
    const redirect = window.sessionStorage.getItem('redirectAfterLogin');
    clearRedirectUri();
    return redirect;
}

/**
 * Remove the requested url from the session storage.
 */
export function clearRedirectUri(): void {
    window.sessionStorage.removeItem('redirectAfterLogin');
}

/**
 * Store the url path and query into the session storage.
 * When the site is loaded again (after the login), the user
 * will be redirected to this location.
 */
export function persistInitialTargetUri(): void {
    if (window.location.pathname.startsWith('/login')) return;

    const query = window.location.search;
    const path = window.location.pathname;
    window.sessionStorage.setItem('redirectAfterLogin', path + query);
}